<template>
    <k-layout v-if="technology" class="tw-bg-white">
        <k-container class="tw-py-16">
            <template #default>
                <section>
                    <h1 class="tw-heading-1">{{ technology.name }}</h1>
                    <p class="tw-mt-2">{{ technology.category }}</p>
                    <p class="tw-mt-10 tw-leading-8">
                        {{ technology.description }}
                    </p>
                </section>

                <section class="tw-mt-16" v-if="projects && projects.length > 0">
                    <h2 class="tw-heading-2">{{ technology.name }} {{ $t('dictionary.detail.subtitles.jobs') }}</h2>
                    <div class="tw-mt-8 tw-space-y-6">
                        <project-preview :project="project" v-for="project in projects" :key="project.id" />
                    </div>
                </section>
            </template>

            <template #aside>
                <aside v-if="events && events.length > 0">
                    <h4 class="tw-heading-4 tw-border-b-2 tw-border-gray-200 tw-pb-6">{{ technology.name }} {{ $t('dictionary.detail.subtitles.events') }}</h4>
                    <div class="tw-mt-4">
                        <ul class="tw-list-none tw-p-0 tw-divide-y tw-divide-gray-100">
                            <li v-for="event in events" :key="event.id" class="tw-py-6">
                                <event-preview :event="event" show-location />
                            </li>
                        </ul>
                    </div>
                </aside>
            </template>
        </k-container>
    </k-layout>
    <k-layout v-else-if="notFound" class="tw-bg-white">
        <k-container class="tw-py-16">
            <template #default>
                <section>
                    <h1 class="tw-heading-1 tw-uppercase">{{ $route.params.id }}</h1>
                    <p class="tw-mt-10 tw-leading-8">
                        {{ $t('dictionary.detail.descriptions.no_description') }}
                    </p>
                </section>
            </template>

            <template #aside>
                {{ technologies }}
            </template>
        </k-container>
    </k-layout>
</template>

<script>
import ProjectPreview from "./../../projects/components/project-preview-new";
import EventPreview from "./../../events/components/event-preview";
import { formatDateRange } from "./../../utils/date";

const normalizeTechnologyName = name => {
    return name
        ? String(name)
              .toLowerCase()
              .replace(/\s/g, "")
        : "";
};

export default {
    components: {
        ProjectPreview,
        EventPreview
    },

    data() {
        return {
            notFound: false
        };
    },

    created() {
        this.$vuetify.goTo(0);

        setTimeout(() => {
            if (!this.technology) {
                this.notFound = true;
            }
        }, 500);
    },

    computed: {
        technology() {
            const { id } = this.$route.params;
            return this.$store.getters["ENUMS/allTechnologiesIndexByNormalizedName"][normalizeTechnologyName(id)];
        },

        projects() {
            if (this.technology) {
                const items = this.$store.getters["NEW_PROJECTS/allProjects"];
                return items.filter(item => Array.isArray(item.technologies) && item.technologies.includes(this.technology.name));
            } else {
                return [];
            }
        },

        events() {
            if (this.technology) {
                const items = this.$store.getters["EVENTS/events"];
                return items.filter(item => Array.isArray(item.topics) && item.topics.includes(this.technology.name.toLowerCase()));
            } else {
                return [];
            }
        }
    },

    methods: {
        formatDateRange
    }
};
</script>
